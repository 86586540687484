export async function fetchTimeout (resource, options) {
    options = options || {}
    const { timeout = 8000 } = options
    delete options.timeout
    const controller = new AbortController()
    const timeoutID = setTimeout(() => {
        controller.abort()
    }, timeout)
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
    })
    clearTimeout(timeoutID)
    return response
}

export function formatBytes (int) {
    if (int === 0) {
        return '0 Bytes'
    }
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    let i = Math.floor(Math.log(int) / Math.log(1024))
    if (i > (units.length - 1)) {
        i = units.lenth - 1
    }
    return parseFloat((int / Math.pow(1024, i)).toFixed(1)) + ' ' + units[i]
}

export function parseStrictInt (str) {
    if (/^[-+]?\d+$/.test(str)) {
        return parseInt(str)
    } else {
        return NaN
    }
}

export function headersWithKey (api_key) {
    if (api_key === undefined) {
        console.log('its undefined')
        return {}
        //        console.log(new Error().stack)
    } else if (api_key !== null) {
        return { 'x-api-key': api_key }
    } else {
    //    console.log("Using: no x-api-key")
        return {}
    }
}

export function getApiKeyInterceptors (api_key) {
    let interceptors = []

    if (api_key !== null) {
        console.log(' -> ')
        //    let api_key = localStorage.getItem('api_key')
        // console.log("getApiKeyInterceptors(): Preparing interceptor, api_key = " + api_key)
        const SimpleUnaryInterceptor = function () {
        }

        SimpleUnaryInterceptor.prototype.intercept = function (request, invoker) {
            const md = request.getMetadata()
            console.log('getApiKeyInterceptors() : api_key =' + api_key)
            md['x-api-key'] = 'my_super_secret_key'

            return invoker(request).then((response) => {
                return response
            })
        }
        interceptors.push(new SimpleUnaryInterceptor())
    } else {
        console.log('No api_key found in localstorage')
    }
    return interceptors
}

export default {
    fetchTimeout,
    formatBytes,
    parseStrictInt,
}
