import {createRouter, createWebHistory} from "vue-router";


const routes = [
    {
        path: '/',
        // name: 'home',
        // component: Home,
        name: 'Home',
        component: () => {
            return import(/* webpackChunkName: "about" */ './components/Monitor.vue')
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach( async (to) => {

    const api_key = to.query.api_key
    const broker = to.query.broker

    console.log(api_key)

    if (api_key !== undefined && broker !== undefined) {
        localStorage.setItem('beamyServerIp', broker)
        localStorage.setItem('beamyApiKey', api_key)
        await router.push('/')
        window.location.reload()
    } else {
        console.log("ip " + localStorage.getItem('beamyServerIp'))
        console.log("key " + localStorage.getItem('beamyApiKey'))
    }

})

export default router;
