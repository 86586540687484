<template>



  <div v-if="hasBrokerAndApiKeySet()" class="chart">

    <!--
        <input type="text" v-model="URL"/>
    -->

    <div v-if="namespace">


<!--    <table>
      <th>Namespace</th>
      <th>Mode</th>
      <th>Path</th>
      <tr>
        <td>{{ namespace }}</td>
        <td>{{ mode }}</td>
        <td>{{ path }}</td>
      </tr>
    </table>
<br/>

    <IconButton data-bs-toggle="tooltip" data-bs-placement="top" icon="pause_circle" size="medium" @click="sendPauseCommand()"/>
    <IconButton icon="play_circle" size="medium"  @click="sendPlayCommand()">Play</IconButton>
    <IconButton icon="stop_circle" size="medium"  @click="sendStopCommand()">Stop</IconButton>
-->
    <!--<IconButton
        icon="subscriptions" size="medium"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal" @click="search = ''"/>
-->
<!--

    <Chart :signal-spec="checkedItems" :new-signals="signalDataList" :mode="mode"/>
    <br/>
-->

<div class="card" >
  <b>Perfect! You have successfully started a recording on your broker</b>
</div>



<div class="info">
  Now its time to explore signals in this recording which you can do in two ways.
  <br>
  For issues with any of the steps below please use our community discussions on github,
  <a href="https://github.com/remotivelabs/remotivelabs-community/discussions">github.com/remotivelabs/remotivelabs-community/discussions</a>
  or send an email to <a href="mailto:support@remotivelabs.com">support@remotivelabs.com</a>
  <br><br>
  1. Open in RemotiveExplorer by pressing "Explore" button above. Here its possible to subscribe to signals and
  have those displayed in graphs + start, stop and pause the recording.
  <br><br>
  2. Checkout our cloud-demo-samples and run code against this recording + dig into and change the code.
  More info in the sample projects README.md
  <br><br>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active"
              id="python-tab" data-bs-toggle="tab" data-bs-target="#python"
              type="button" role="tab" aria-controls="home" aria-selected="true">Python</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
              type="button" role="tab" aria-controls="profile" aria-selected="false">C++</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="android-tab" data-bs-toggle="tab" data-bs-target="#android"
              type="button" role="tab" aria-controls="android" aria-selected="false">Android AAOS</button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="python" role="tabpanel" aria-labelledby="python-tab">
      <!-- Python stuff -->
      <p style="font-style: italic">
        git clone https://github.com/remotivelabs/remotivelabs-samples<br>
        cd remotivelabs-samples/python/cloud-demo<br/>
        pip3 install -r requirements.txt<br/>
        python3 cloud-demo.py \<br>
        &nbsp;&nbsp;&nbsp;&nbsp; --url {{ URL}} \<br>
        &nbsp;&nbsp;&nbsp;&nbsp; --api-key {{ apiKey }} \<br>
        &nbsp;&nbsp;&nbsp;&nbsp; --signals VehicleSpeed,ChassisAcceleratorPedalposition

      </p>

    </div>
    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
      <p style="font-style: italic">
        git clone https://github.com/remotivelabs/remotivelabs-samples<br>
        Follow the instructions here https://github.com/remotivelabs/remotivelabs-samples/tree/main/cpp
      </p>
    </div>

    <div class="tab-pane fade" id="android" role="tabpanel" aria-labelledby="android-tab">
      <p style="font-style: italic">
        git clone https://github.com/remotivelabs/remotivelabs-android-vhal.git
        Follow the instructions here https://github.com/remotivelabs/remotivelabs-android-vhal
      </p>
    </div>

  </div>

</div>

    </div>
    <div v-else>
      No recording active on broker, please choose a recording to play
    </div>



  </div>
  <div v-else>
    No broker and api-key found
  </div>

  <Modal title="Signal List">
    <template v-slot:footer>
      <p v-if="results.length === 0 && search != ''">No matching signals</p>
      <vue3-tree-vue :items="selectedSignals"
                     :isCheckable="true"
                     :hideGuideLines="false"
                     v-model:checkedItems="checkedItems"
      />
    </template>

    <template v-slot:body>
      <input type="text" v-model="search"/>
      <!--      <p v-if="noResults">Sorry, no results for {{search}}</p>
            <div v-for="(r, i) in results" :key="i">
              {{ r.name }}
            </div>
      -->
    </template>

  </Modal>


</template>

<script>
import * as api from '../grpc/dist/api.js'
import useGrpcApi from "@/grpc-api-helper";
import {headersWithKey} from "@/utils";
import {defineComponent, reactive, toRefs} from "vue";
import Vue3TreeVue from 'vue3-tree-vue'
import {useVueFuse} from 'vue-fuse'

//import Chart from "@/components/Chart";
import Modal from "@/components/Modal.vue";
//import IconButton from "@/components/IconButton.vue";

export default defineComponent({
  name: 'HelloWorld',
  components: {
    //Chart,
    Modal,
    //IconButton,
    Vue3TreeVue},
  props: {
    msg: String
  },

  watch: {
    namespace() {
      console.log("listing signals")
      if (this.namespace !== "undefined") {
        this.listSignals()
      }
    },

    results() {
      console.log("changed")
      this.renderTree()
    },

    search() {
      console.log("search")
      this.renderTree()
    },

    checkedItems() {
      this.subscribe()
    }

  },

  setup() {


    const broker = localStorage.getItem("beamyServerIp")
    const apiKey = localStorage.getItem("beamyApiKey")
    const URL = broker //"https://sunnnyvale-beamyhack-sglnqbpwoa-ez.a.run.app"
//    const apiKey = apiKey //'59F0A5E8-E55B9D3A-51D69B99-74B06BF6'

    const helper = useGrpcApi(URL, apiKey)


    const state = reactive({
      mode: undefined,
      namespace: undefined,
      path: undefined,
      count: 0,
      time: Date.now(),
      pause: false,
      data: [],
      signalDataList: [],
      selectedSignals: [],
      allSignalsTree: [],
      checkedItems: []
    })


    const {search, results, noResults, loadItems} = useVueFuse([], {
      keys: ['name'],
      ignoreLocation: true,
      threshold: 0.2
    })

    return {
      apiKey, URL, helper, search,
      results, loadItems,
      noResults, ...toRefs(state)
    }
  },

  mounted() {

    let stream = this.helper.trafficServiceClient.playTrafficStatus(new api.default.Empty(),
        headersWithKey(this.apiKey))

    stream.on('data', response => {

      const playbackInfoList = response.getPlaybackinfoList()

      const res = this.helper.getPlaybackStates(playbackInfoList)
      res.map((row) => {
        console.log(JSON.stringify(row))
        this.namespace = row.namespace
        this.path = row.path
        this.mode = row.mode
        this.count++
      })

      //console.log(response)
    })

    stream.on('error', (err) => {
      console.error('Stream error: ', err)
    })

    stream.on('end', () => {
      console.log('end')
      const millis = Date.now() - this.time;

      console.log(`seconds elapsed = ${Math.floor(millis)}`);
    })
  },
  methods: {

    hasBrokerAndApiKeySet() {
      const broker = localStorage.getItem("beamyServerIp")
      const apiKey = localStorage.getItem("beamyApiKey")

      return broker && apiKey

    },

    sendPlayCommand() {
      this.helper.sendAllPlaybackCommand(0, this.mode, this.path, this.namespace)
    },

    sendPauseCommand() {
      this.helper.sendAllPlaybackCommand(1, this.mode, this.path, this.namespace)
    },

    sendStopCommand() {
      this.helper.sendAllPlaybackCommand(2, this.mode, this.path, this.namespace)
    },

    processBuffer() {
      // TODO - base on time as well as size
      if (this.data.length > 4) {
        this.signalDataList = this.data.splice(0)
      }
    },

    handleInitialSignalTree(frameGroups) {
      this.allSignalsTree.value = frameGroups.map((g) => {
        return {
          id: g.id,
          type: 'parent',
          // eslint-disable-next-line
          name: g.name,
          // eslint-disable-next-line
          children: g.children.map((c) => {
                return {
                  id: c.id,
                  parentId: g.id,
                  type: 'child',
                  // eslint-disable-next-line
                  name: c.name
                }
              }
          )
        }
      })

      let searchData = []
      this.allSignalsTree.value.map((s) => {
        searchData = searchData.concat(s.children)
      })
      this.loadItems(searchData)
    },

    renderTree() {
      console.log(this.search)
      if(this.search.length == "") {
        this.selectedSignals = this.allSignalsTree.value
        return
      }

      const ids = this.results.map((r) => r.id)
      //console.log(JSON.stringify(ids))
      this.selectedSignals = this.allSignalsTree.value.map((signal) => {
        const child = signal.children.filter((c) => {
          //console.log(c.id)
          const b = ids.includes(c.id)
          //console.log(b)
          return b
        })
        return {
          id: signal.id,
          name: signal.name,
          children: child
        }
      }).filter((s) => s.children.length > 0)
      //console.log(JSON.stringify(this.selectedSignals))

    },

    listSignals() {
      this.helper.listSignals(this.namespace, this.handleInitialSignalTree)
    },

    subscribe() {
      //console.log(JSON.stringify(this.subConfig))
      console.log(JSON.stringify(this.checkedItems))

      this.helper.subscribe(this.checkedItems.map((s) => s.name), this.namespace, this.onSignal)
    },

    onSignal(pos) {
      this.data.push(pos)
      this.processBuffer()
    }
  }
})

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

th {
  padding-right: 2rem;
}
td {
  padding-right: 2rem;
}

body {
  font-family: "Lexend Deca";
}

.card {
  font-family: "Lexend Deca";
  font-size: 14px;
  padding: 1rem;
  margin-top:1rem;
  background-color: #42b983;
  #background-color: #1AC472;
}

.info {
  font-family: "Lexend Deca";
  font-size: 14px;
  padding: 1rem;
  margin-top:1rem;
  text-align: left
}

.docs {
  border-style: dot-dot-dash;
  background-color: green;
  color: white;
  margin: 1rem;
  padding: 1rem;
  text-align: left;
}

.chart {
  #border-style: dashed;
  display: inline-block;
  #position: relative;
  #padding: 2rem;
  width: 80%;
}

table {
  display: inline-block;
  position: relative;
}

.tab-pane {
  margin: 2rem;
}


</style>
