import * as api from './grpc/dist/api.js'
import {headersWithKey} from "@/utils";


export default function useGrpcApi(url, apiKey) {

    const networkServiceClient = new api.default.NetworkServiceClient(url)
    const trafficServiceClient = new api.default.TrafficServiceClient(url)
    const systemServiceClient = new api.default.SystemServiceClient(url)

    function subscribe(signalNames, namespace, callback) {
        const subConfig = createSignals(signalNames, "myClientId", namespace)
        const stream = networkServiceClient.subscribeToSignals(subConfig, headersWithKey(apiKey)) //{'x-api-key': 'my_super_secret_key'}
        //this.subscribed = true
        //this.rememberSubscribe = true
        stream.on('data', response => {
            try {
                let signalList = response.getSignalList()
                signalList.forEach(grpcSignal => {
                    const signal = fromGrpcSignal(grpcSignal)
                    // We prepare to plot it here
                    callback({
                        x: signal.rawData[0].timestamp / 1000,
                        y: signal.dataRecords[0].data,
                        name: signal.name
                    })
//                this.processBuffer()
                })
            } catch (e) {
                console.log(e)
            }
        })
        stream.on('end', () => {
            console.log('end')
        })
        stream.on('status', (status) => {
            console.log(status)
        })
        stream.on('error', (error) => {
            console.error(error)
        })
    }

    function listSignals(namespace, callback) {

        const frameGroup = []
        const parentName = namespace//namespaceName.getNamespace().getName()
        //this.request = 'Fetch signals'
        // eslint-disable-next-line no-undef
        //const client = new api.default.SystemServiceClient(this.beamyServerIp)
        // eslint-disable-next-line no-undef
        const request = new api.default.NameSpace()
        request.setName(parentName)
        if (this.connectionStatus !== 'success--text') {
            this.connectionStatus = 'success--text'
        }

        systemServiceClient.listSignals(request, headersWithKey(apiKey), function listSignalsCallback(err, response) {
            if (response) {
                let childIndex = 0
                const frames = response.getFrameList()
                frames.forEach(frame => {
                    const frameMetaData = frame.getSignalinfo().getMetadata()
                    const frameSignalId = frame.getSignalinfo().getId()
                    const frameName = frameSignalId
                        .getName()
                    const frameChildInfo = frame.getChildinfoList()
                    const frameChildGroup = []
                    frameChildInfo.forEach(frameChild => {
                        const frameChildSignalId = frameChild.getId()
                        const frameChildMetaData = frameChild.getMetadata()
                        const frameChildName = frameChildSignalId.getName()
                        const frameChildLabel = {
                            id: parentName + frameChildName + childIndex,
                            name: frameChildName,
                            namespace: parentName,
                            frameName: frameName,
                            children: [],
                            signalId: frameChildSignalId,
                            min: frameChildMetaData.getMin(),
                            max: frameChildMetaData.getMax(),
                            size: frameChildMetaData.getSize(),
                            unit: frameChildMetaData.getUnit(),
                            description: frameChildMetaData.getDescription(),
                            isParent: false,
                        }
                        childIndex += 1
                        frameChildGroup.push(frameChildLabel)
                    })
                    frameGroup.push({
                        id: parentName + frameName + childIndex,
                        name: frameName,
                        namespace: parentName,
                        children: frameChildGroup,
                        signalId: frameSignalId,
                        min: frameMetaData.getMin(),
                        max: frameMetaData.getMax(),
                        size: frameMetaData.getSize(),
                        unit: frameMetaData.getUnit(),
                        description: frameMetaData.getDescription(),
                        isParent: true,
                    })
                    childIndex += 1
                })
                callback(frameGroup)
            }
            if (err) {
                console.log('error', err)
            }
        })

    }


    function fromGrpcSignal(signal) {
        const name = signal.getId().getName()
        const nameSpace = signal.getId().getNamespace()
            .getName()
        // console.log(`${name}, ${nameSpace}`)
        let dataType = ''
        let signalData = ''
        if (signal.hasInteger()) {
            signalData = signal.getInteger()
            dataType = 'Integer'
        } else if (signal.hasDouble()) {
            signalData = signal.getDouble()
            dataType = 'Double'
        } else if (signal.hasArbitration()) {
            signalData = signal.getArbitration()
            dataType = 'Arbitration'
        } else if (signal.hasEmpty()) {
            signalData = signal.getEmpty()
            dataType = 'Empty'
        }
        let rawData
        rawData = signal.getRaw_asU8()
        let isParent = false
        if (rawData.length > 7) {
            dataType = 'RAW'
            isParent = true
        }
        const timestamp = signal.getTimestamp()
        const newElement = {
            name: name,
            dataRecords: [{timestamp: timestamp, data: signalData}],
            dataType: dataType,
            nameSpace: nameSpace,
            highlight: false,
            rawData: [{timestamp: timestamp, data: rawData}],
            isParent: isParent,
        }
        return newElement
    }

    function createSignals(signalNames, clientId, namespace) {
        const signals = []
        const subConfig = new api.default.SubscriberConfig()
        signalNames.map((s) => {
            console.log("subscribing to " + s.name)
            const signal = createSignal(s, clientId, namespace)
            signals.push(signal)
        })

        // eslint-disable-next-line no-undef
        const signalIds = new api.default.SignalIds()
        signalIds.setSignalidList(signals)
        // eslint-disable-next-line no-undef
        const cId = new api.default.ClientId()
        cId.setId(clientId)
        subConfig.setSignals(signalIds)
        subConfig.setClientid(cId)
        return subConfig
    }

    function createSignal(signalName, clientId, namespace) {

        //const request = 'Subscribe'

        const signal = new api.default.SignalId()
        signal.setName(signalName)
        const nameSpace = new api.default.NameSpace()
        nameSpace.setName(namespace)
        signal.setNamespace(nameSpace)
        return signal
    }

    function getMode(mode) {
        switch (mode) {
            case 'start' :
                return api.default.Mode.START
            case 'stop' :
                return api.default.Mode.STOP
            case 'pause' :
                return api.default.Mode.PAUSE
            default :
                return undefined
        }
    }

    /**
     *
     * @param command 0 = play, 1 = pause, 2 = stop
     * @param client
     * @param mode
     * @param path
     * @param namespace
     * @returns {Promise<void>}
     */
    async function sendAllPlaybackCommand(command, mode, path, namespace) {
        console.log(command)

        //const mode = this.getMode(command)

        // eslint-disable-next-line no-undef
        const playbackInfos = new api.default.PlaybackInfos()
        //mode: api.default.Mode.PAUSE,
        //    register: 'playback',
        const infos = Object.values(
            {
                "file":
                    {
                        path: path,
                        namespace: namespace,
                        //sha256: "",
                        //startTime: 0,
                        //endTime: 0,
                        //mode: mode,
                        //offsetTime: 104550377,
                        //register: "playback"
                    }
            }
        )
            /*.filter(info => {
              return info.register === 'playback'//opts.register
            })*/
            .map(info => {
                // eslint-disable-next-line no-undef
                const playbackmode = new api.default.PlaybackMode()
                // eslint-disable-next-line no-undef
                const playbackInfo = new api.default.PlaybackInfo()

                // eslint-disable-next-line no-undef
                const config = new api.default.PlaybackConfig()
                // eslint-disable-next-line no-undef
                const desc = new api.default.FileDescription()
                // eslint-disable-next-line no-undef
                const namespace = new api.default.NameSpace()

                desc.setPath(info.path)
                namespace.setName(info.namespace)

                config.setFiledescription(desc)
                config.setNamespace(namespace)

                playbackmode.setMode(command)
                playbackInfo.setPlaybackconfig(config)
                playbackInfo.setPlaybackmode(playbackmode)
                return playbackInfo
            })

        playbackInfos.setPlaybackinfoList(infos)
        const err = await new Promise(resolve => {
            trafficServiceClient.playTraffic(playbackInfos, headersWithKey(apiKey), (err, response) => {
                if (response) {
                    resolve()
                    return true
                } else if (err) {
                    resolve(err)
                    return false
                }
            })
        })

        if (err) {
            console.warn('Error requesting to set play back information', err)
        }
    }


    /**
     * List of proto: PlaybackInfos
     * Looks like there is always one though
     *
     * @param playbackInfos
     */
    function getPlaybackStates(playbackInfos) {
        return playbackInfos.map(info => {

            const config = info.getPlaybackconfig()
            const file = config.getFiledescription()
            const namespace = config.getNamespace()
            const mode = info.getPlaybackmode()
            return {
                path: file.getPath(),
                namespace: namespace.getName(),
                sha256: file.getSha256(),
                //startTime: 0,
                endTime: 0,
                ...(() => {
                    switch (mode.getMode()) {
                        // eslint-disable-next-line no-undef
                        case api.default.Mode.PLAY:
                            return {
                                mode: 'play',
                                offsetTime: mode.getOffsettime(),
                                start: mode.getStarttime(),
                                register: 'playback',
                            }

                        // eslint-disable-next-line no-undef
                        case api.default.Mode.PAUSE:
                            return {
                                mode: 'pause',
                                offsetTime: mode.getOffsettime(),
                                register: 'playback',
                            }
                        // eslint-disable-next-line no-undef
                        case api.default.Mode.STOP:
                            return {
                                mode: 'stop',
                                offsetTime: 0,
                            }
                        // eslint-disable-next-line no-undef
                        case api.default.Mode.RECORD:
                            return {
                                mode: 'record',
                                offsetTime: mode.getOffsettime(),
                                register: 'record',
                            }
                    }
                })(),
            }
        })
    }

    return {
        trafficServiceClient, subscribe, getPlaybackStates, sendAllPlaybackCommand,
        getMode, createSignal, fromGrpcSignal, listSignals
    }
}